import { StyledAlertContainer } from '../style';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginState } from 'state/hooks/login.state.hook';
import { Alert } from '@appcharge/shared-ui';
import { motion } from 'framer-motion';

export const ConsentAlert: React.FC = () => {
  const { shouldShowTermsAlert, isTermsAlertShaking } = useLoginState();
  const { t } = useTranslation();

  return (
    <StyledAlertContainer isVisible={Boolean(shouldShowTermsAlert)}>
      <motion.div
        animate={{
          x: isTermsAlertShaking ? ['0%', '-2%', '2%', '-2%', '2%', '0%'] : '0%',
        }}
        transition={{
          duration: 0.5,
          repeatType: 'loop',
        }}
      >
        <Alert text={t('login.consentTermsAlert')} variant={'danger'} />
      </motion.div>
    </StyledAlertContainer>
  );
};
