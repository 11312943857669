import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLoginTermsBlock } from '../style';
import { useLoginPage } from '../useLoginPage.hook';
import { EBundlesInternalViewModel } from '../../../constants/enums';
import { AdvancedTerms } from './AdvancedTerms';
import { SimpleTerms } from './SimpleTerms';

interface ILoginTermsBlockProps {
  textColor?: string;
  textSize?: string;
  privacyPolicyLink?: string;
  termsAndConditionsLink?: string;
}

export const LoginTermsBlock: React.FC<ILoginTermsBlockProps> = ({
  textColor = 'black',
  textSize = '16px',
  privacyPolicyLink,
  termsAndConditionsLink,
}) => {
  const { publisherMetaData } = useLoginPage();
  const { t } = useTranslation();

  const isAdvancedTextFeatureEnabled = useMemo(
    () => publisherMetaData.featureFlags.dashboard_new_privacy_rich_text,
    []
  );

  const isProductMadness = [
    EBundlesInternalViewModel.JACKPOT_SLOTS,
    EBundlesInternalViewModel.BIG_FISH_CASINO,
    EBundlesInternalViewModel.CASHMAN_CASINO,
    EBundlesInternalViewModel.MIGHTY_FU_CASINO,
    EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
    EBundlesInternalViewModel.HEART_OF_VEGAS,
  ].includes(publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel);

  return (
    <StyledLoginTermsBlock textColor={textColor} textSize={textSize} className={'login-terms-text'}>
      {isAdvancedTextFeatureEnabled ? (
        <AdvancedTerms textColor={textColor} />
      ) : (
        <SimpleTerms
          privacyPolicyLink={privacyPolicyLink}
          termsAndConditionsLink={termsAndConditionsLink}
        />
      )}
      {isProductMadness && (
        <>
          <br />
          <span>{t('login.termsText')} </span>
        </>
      )}
    </StyledLoginTermsBlock>
  );
};
