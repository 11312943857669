import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  getIntegrationProfileOptionStyles,
  StyledIntegrationProfilePickerContainer,
  StyledIntegrationProfileSelect,
} from '../style';
import useApi from 'hooks/useApi';
import { FC, useEffect, useState } from 'react';
import { localStorageUtil } from 'utils';
import { ELocalStorageKeys } from 'constants/enums';
import { BootResponse } from 'constants/apiResponses.types';

const DEFAULT_PROFILE_PLACEHOLDER = 'Default';

interface IntegrationProfilePickerProps {
  disabled?: boolean;
}

export const IntegrationProfilePicker: FC<IntegrationProfilePickerProps> = ({ disabled }) => {
  const API = useApi({});
  const [currentProfile, setCurrentProfile] = useState<string>(
    localStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE) || ''
  );
  const [profilesList, setProfilesList] = useState<string[]>([]);
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const fontFamily =
    publisherMetaData.storeTheme.login.font || publisherMetaData.storeTheme.general.font;
  const handleSelect = (event: SelectChangeEvent<string>) => {
    setCurrentProfile(event.target.value);
  };

  useEffect(() => {
    if (!API.getPublisherMeta.data) return;
    if (disabled) return;
    setProfilesList(API.getPublisherMeta.data.integration.integrationProfiles);
  }, [API.getPublisherMeta.data, disabled]);

  useEffect(() => {
    localStorageUtil.set(ELocalStorageKeys.INTEGRATION_PORFILE, currentProfile);
  }, [currentProfile]);

  return (
    <StyledIntegrationProfilePickerContainer>
      <Box sx={{ minWidth: '100%' }}>
        <FormControl sx={{ minWidth: '100%' }}>
          <StyledIntegrationProfileSelect
            displayEmpty
            value={currentProfile}
            onChange={handleSelect}
            inputProps={{ 'aria-label': 'Without label' }}
            fontFamily={fontFamily}
            renderValue={(value: string) => `Profile: ${value || DEFAULT_PROFILE_PLACEHOLDER}`}
            MenuProps={getIntegrationProfileOptionStyles(fontFamily)}
            IconComponent={KeyboardArrowDownIcon}
            disabled={disabled}
          >
            <MenuItem value={''}>{DEFAULT_PROFILE_PLACEHOLDER}</MenuItem>
            {profilesList?.map((profile) => (
              <MenuItem key={profile} value={profile}>
                {profile}
              </MenuItem>
            ))}
          </StyledIntegrationProfileSelect>
        </FormControl>
      </Box>
    </StyledIntegrationProfilePickerContainer>
  );
};
