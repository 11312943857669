import React, { useEffect } from 'react';
import { ELoginMethods, ELoginMode } from 'constants/enums';
import { FacebookLogin } from '../Externals/Facebook';
import { GoogleLogin } from '../Externals/Google';
import { LoginButton } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';
import { AppleLoginButton } from '../Externals/Apple';

export const ProvidersMode: React.FC = () => {
  const { publisherMetaData, isWebview, showOtpButton } = useLoginPage();
  const { hasConsent, navigateToMode, loginButtonClickWrapper, setMode } = useLoginState();
  const { t } = useTranslation();

  const playerAuthData = publisherMetaData.integration.playersAuthentication;

  const { otpButton } = publisherMetaData.storeTheme.login;

  useEffect(() => {
    setMode(ELoginMode.PROVIDERS);
  }, []);

  return (
    <>
      {showOtpButton && (
        <LoginButton
          textColor={otpButton?.textColor}
          iconSrc={otpButton?.icon}
          iconSize={'32px'}
          backgroundColor={otpButton?.backgroundColor}
          text={otpButton?.text}
          callback={() =>
            loginButtonClickWrapper(() => navigateToMode(ELoginMode.OTP), ELoginMethods.OTP)
          }
          dataTestId="otp-login-button"
          isDisabled={!hasConsent}
          opacityOnDisabled={false}
        />
      )}
      {!isWebview && playerAuthData.fbOn && playerAuthData.fbAppId && <FacebookLogin />}
      {!isWebview && playerAuthData.googleOn && playerAuthData.googleAppId && <GoogleLogin />}
      {!isWebview && playerAuthData.appleOn && playerAuthData.appleAppId && <AppleLoginButton />}
      {!isWebview && playerAuthData.usernamePasswordOn && (
        <LoginButton
          variant="transparent"
          text={t('login.loginWithUserName')}
          callback={() =>
            loginButtonClickWrapper(
              () => navigateToMode(ELoginMode.USERNAME),
              ELoginMethods.USERNAME
            )
          }
          dataTestId="username-login-button"
          isDisabled={!hasConsent}
          opacityOnDisabled={false}
        />
      )}
      {!isWebview && playerAuthData.userTokenOn && (
        <LoginButton
          variant="transparent"
          text={t('login.loginWithPlayerId')}
          callback={() =>
            loginButtonClickWrapper(() => navigateToMode(ELoginMode.TOKEN_ID), ELoginMethods.TOKEN)
          }
          dataTestId="playerId-login-button"
          isDisabled={!hasConsent}
          opacityOnDisabled={false}
        />
      )}
    </>
  );
};
