import { Gradient } from '@appcharge/shared-ui';
import { Box } from '@mui/material';
import styled, { css } from 'styled-components';
import { createGradientOrSolid } from 'utils';

export const StyledCloseIconWrapper = styled.div<{ opacity: number }>`
  width: 100%;
  display: flex;
  justify-content: end;
  min-height: 50px;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props: any) => props.opacity};
`;

interface StyledHeaderProps {
  headerColor: string;
  headerSize: number;
  headerWeight: number | string;
}

interface StyledSubHeaderProps {
  textColor: string;
  textSize: number;
  textWeight: number | string;
}

export const StyledHeader = styled.h1<StyledHeaderProps>`
  text-align: center;
  margin: unset;
  color: ${(props: any) => props.headerColor};
  font-size: ${(props: any) => props.headerSize + 'px'};
  font-weight: ${(props: any) => props.headerWeight};
`;

export const StyledSubHeader = styled.h2<StyledSubHeaderProps>`
  text-align: center;
  color: ${(props: any) => props.textColor};
  font-size: ${(props: any) => props.textSize + 'px'};
  font-weight: ${(props: any) => props.textWeight};
`;

type StyledAccountApprovalModalPros = {
  backgroundImage?: string;
  backgroundColor: Gradient;
  borderWidth?: number;
  borderColor?: string;
  buttonBackgroundColor: Gradient;
  buttonTextColor?: string;
  textColor?: string;
};

export const StyledAccountApprovalModal = styled(Box)<StyledAccountApprovalModalPros>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ backgroundImage, backgroundColor }) =>
    backgroundImage ? `url("${backgroundImage}")` : createGradientOrSolid(backgroundColor)};
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-repeat: no-repeat;
      background-size: cover;
    `}
  color: ${({ textColor }: any) => textColor || '#FFFFFF'};
  width: 343px;
  height: 343px;
  border-radius: 15px;
  border: ${({ borderWidth }: any) => borderWidth || 2}px solid
    ${({ borderColor }: any) => borderColor || '#FFFFFF'};
  padding: 40px 43px 16px 43px;

  .approve-button {
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    background: ${({ buttonBackgroundColor }: any) =>
      buttonBackgroundColor && createGradientOrSolid(buttonBackgroundColor)};
    color: ${({ buttonTextColor }: any) => buttonTextColor || '#FFFFFF'};
    text-transform: none;
    border-radius: 8px;

    :hover {
      background: ${({ buttonBackgroundColor }: any) =>
        buttonBackgroundColor && createGradientOrSolid(buttonBackgroundColor)};
    }
  }

  .disapprove-button {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: ${({ buttonTextColor }: any) => buttonTextColor || '#FFFFFF'};
    text-transform: none;
    height: 24px;

    :hover {
      background-color: transparent;
    }
  }

  .footer {
    font-size: 10px;
    color: ${({ textColor }: any) => textColor || '#FFFFFF'};
    margin: 0 10px;

    a {
      color: ${({ textColor }: any) => textColor || '#FFFFFF'};
    }
  }
` as any;

export const StyledPlayerDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 72px;
    width: 72px;
    border-radius: 50px;
    border: 2px solid #ffff;
  }
`;
