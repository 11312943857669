import React, { useMemo } from 'react';
import { DailyBonusBadge } from '@appcharge/shared-ui';
import { Stack } from '@mui/material';
import { EBundlesInternalViewModel } from '../../../constants/enums';
import { usePopupState } from 'state/hooks/popup.state.hook';
import useApi from 'hooks/useApi';
import { BootResponse } from 'constants/apiResponses.types';

enum DailyBonusPosition {
  LEFT = 'start',
  CENTER = 'center',
  RIGHT = 'end',
}

const maxWidthConfigs: Record<any, any> = {
  [EBundlesInternalViewModel.BIG_FISH_CASINO]: '1024px',
  [EBundlesInternalViewModel.JACKPOT_SLOTS]: '1024px',
};

const getMaxWidth = (viewModel: EBundlesInternalViewModel) =>
  maxWidthConfigs[viewModel] || '1248px';

export const DailyBonusWrapper: React.FC = () => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;

  const {
    popupOffers,
    dailyBonusBadgesNextDate,
    dailyBonusBadges,
    setCurrentPopupDisplayed,
    setPopupIndexToCollect,
  } = usePopupState();

  const font = useMemo(
    () => publisherMetaData.storeTheme.general.font,
    [publisherMetaData?.storeTheme?.general?.font]
  );

  const maxWidth = useMemo(
    () => getMaxWidth(publisherMetaData.storeTheme.general.bundlesInternalViewModel),
    [publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel]
  );

  const onClickBadge = (offerId?: string) => {
    const clickedPopupBadge = popupOffers?.find((offer) => offer.offerId === offerId);
    if (clickedPopupBadge) {
      setCurrentPopupDisplayed(null);
      setTimeout(() => {
        const updatedPopup = {
          ...clickedPopupBadge,
          isBadgeNextProduct: true,
        };
        if (updatedPopup?.indexToCollect) setPopupIndexToCollect(updatedPopup.indexToCollect - 1);
        setCurrentPopupDisplayed(updatedPopup);
      }, 0);
    }
  };

  const onTimerDoneHandler = () => {
    API.getOffers.refetch();
  };

  if (!dailyBonusBadges || dailyBonusBadges.length === 0) return null;

  return (
    <Stack
      display="flex"
      flexDirection="row"
      gap={'1em'}
      alignItems={DailyBonusPosition.LEFT}
      width="100%"
      height="auto"
      maxWidth={maxWidth}
      padding="16px 20px 16px"
      className={'daily-bonus-badges'}
    >
      {dailyBonusBadges.map((badge) => (
        <DailyBonusBadge
          key={badge?.offerId}
          badgeCoverImage={badge?.badgeCoverImage}
          borderColor={badge?.borderColor || { colorOne: '#ffffff' }}
          offerId={badge.offerId}
          targetDate={dailyBonusBadgesNextDate}
          onTargetDateReach={onTimerDoneHandler}
          borderWidth="2px"
          font={font}
          onClickBadge={onClickBadge}
        />
      ))}
    </Stack>
  );
};
