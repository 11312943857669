import { StyledImageContainer, StyledLoginPage } from '../style';
import { LoginTermsBlock } from '../TermsBlocks/TermsBlock';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ELoginMode } from 'constants/enums';
import { WebviewEscapeScreen } from '../WebviewEscapeScreen/WebviewEscapeScreen';
import { LoginButtonBlocks } from './ButtonBlocks';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';
import { ConsentAlert } from '../TermsBlocks/ConsentAlert';
import { useAppState } from 'state/hooks/app.state.hook';

export const Login: React.FC = () => {
  const { publisherMetaData, isWebview, showOtpButton } = useLoginPage();
  const { handleBackBtnClicked, mode } = useLoginState();
  const { t } = useTranslation();
  const { setBackgroundProps } = useAppState();

  useEffect(() => {
    if (publisherMetaData.supportConfiguration?.postLoginSnippet) {
      (window as any).Helpshift && (window as any).Helpshift('hide');
    }
  }, []);

  const {
    loginLogoSize,
    textSize = 24,
    textColor = 'blue',
    text = 'Welcome to appcharge store!',
    textWeight = 'normal',
  } = publisherMetaData.storeTheme.login;
  const logo =
    publisherMetaData.storeTheme.login?.loginLogoImage ||
    publisherMetaData.storeTheme.general?.logo ||
    'https://media.appcharge.com/defaults/logo.svg';
  const fontFamily =
    publisherMetaData.storeTheme.login.font || publisherMetaData.storeTheme.general.font;

  const footerSettings = useMemo(
    () => publisherMetaData.storeTheme.general?.footer,
    [publisherMetaData.storeTheme.general?.footer]
  );

  const displayTermsBlock = !isWebview || showOtpButton;

  const displayWebviewEscapeScreen = isWebview && mode === ELoginMode.PROVIDERS && !showOtpButton;

  useEffect(() => {
    if (!publisherMetaData?.storeTheme) return;

    setBackgroundProps({
      height: '',
      minHeight: '100vh',
      width: '100vw',
      backgroundImageMobile:
        publisherMetaData.storeTheme.login.loginBackgroundImageMobile ||
        publisherMetaData.storeTheme.general.backgroundImageMobile,
      backgroundImageDesktop:
        publisherMetaData.storeTheme.login.loginBackgroundImageDesktop ||
        publisherMetaData.storeTheme.general.backgroundImageDesktop,
      backgroundPosition: 'center top',
      backgroundSize: 'cover',
    });
  }, [publisherMetaData?.storeTheme]);

  return (
    <StyledLoginPage
      fontFamily={fontFamily}
      textColor={textColor}
      textSize={textSize}
      textWeight={textWeight}
    >
      <ConsentAlert />
      <StyledImageContainer>
        <img
          className="logo"
          id={loginLogoSize}
          alt="game logo"
          src={logo}
          style={{
            width: window.location.hostname.includes('matchmaste.rs') ? '230px' : 'auto',
          }}
        />
      </StyledImageContainer>
      {displayWebviewEscapeScreen ? (
        <>
          <WebviewEscapeScreen />
          {showOtpButton && <div className="orText">{t('login.or')}</div>}
        </>
      ) : (
        <h2 data-testid="loginPageTitle">{text}</h2>
      )}
      <LoginButtonBlocks />
      {displayTermsBlock && (
        <LoginTermsBlock
          textColor={textColor}
          privacyPolicyLink={footerSettings.privacyPolicyUrl}
          termsAndConditionsLink={footerSettings.termsAndConditionsUrl}
        />
      )}
      {mode !== ELoginMode.PROVIDERS && (
        <button className="backButton" onClick={() => handleBackBtnClicked()}>
          {t('login.back')}
        </button>
      )}
    </StyledLoginPage>
  );
};
