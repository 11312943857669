import React, { useEffect } from 'react';
import { LoginButton } from '@appcharge/shared-ui';
import { ELoginMethods, ESessionStorageKeys } from '../../../constants/enums';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';
import useUrlHash from 'hooks/useUrlHash';
import { localStorageUtil } from 'utils';
import { useLoginState } from 'state/hooks/login.state.hook';

export const FacebookLogin: React.FC = () => {
  const { publisherMetaData } = useLoginPage();
  const { hasConsent, loginResponse, loginButtonClickWrapper } = useLoginState();
  const facebookAccessToken = useUrlHash('#access_token');
  const appId = publisherMetaData.integration.playersAuthentication.fbAppId;

  const { t } = useTranslation();

  useEffect(() => {
    if (facebookAccessToken) facebookResponseHandler();
  }, [facebookAccessToken]);

  const facebookResponseHandler = () => {
    const data = {
      userToken: facebookAccessToken || undefined,
      authMethod: ELoginMethods.FACEBOOK,
    };
    localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
    loginResponse(data);
  };

  const loginClickHandler = async () => {
    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?response_type=token&client_id=${appId}&redirect_uri=${window.location.origin}${window.location.pathname}&state=facebookredirect`;
  };

  return (
    <LoginButton
      variant="facebook"
      callback={() => loginButtonClickWrapper(loginClickHandler, ELoginMethods.FACEBOOK)}
      text={t('login.signInWithFacebook')}
      dataTestId="facebook-login-button"
      isDisabled={!hasConsent}
      opacityOnDisabled={false}
    >
      <img src="https://media.appcharge.com/defaults/facebook_icon_white.svg" alt="facebook icon" />
    </LoginButton>
  );
};
