import { isMobile, osName, browserName } from 'react-device-detect';
import { BootResponse } from '../../constants/apiResponses.types';
import useApi from '../../hooks/useApi';
import { loginPageService } from './login-page.service';
import { useCallback, useMemo } from 'react';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
  EBundlesInternalViewModel,
  EEventsType,
  EOTPTextModel,
  ESessionStorageKeys,
  EStorePhase,
} from '../../constants/enums';
import { useLocation } from 'react-router-dom';
import { localStorageUtil } from 'state/localStorage.state.service';
import { isDesktop } from 'react-device-detect';

export const useLoginPage = () => {
  const location = useLocation();

  const API = useApi({
    platform: isMobile ? osName : browserName,
  });

  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { sendCustomEvent, getEventsSharedProps } = useCustomEvents();

  const redirectState = localStorageUtil.get(ESessionStorageKeys.REDIRECT_STATE);

  const playerAuthData = useMemo(
    () => publisherMetaData?.integration?.playersAuthentication,
    [publisherMetaData?.integration?.playersAuthentication]
  );

  const isWebview = useMemo(
    () => loginPageService.isWebviewBrowser(),
    [navigator.userAgent, location]
  );

  const trackLoginEvent = useCallback(
    async (eventName: string) => {
      if (eventName === EEventsType.LOGIN_CANCELED && redirectState) {
        sendCustomEvent(
          EEventsType.LOGIN_CANCELED,
          loginPageService.getLoginEventsData({
            eventSharedProps: getEventsSharedProps(),
          }),
          EStorePhase.PRE_LOGIN
        );
        localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
      }
    },
    [publisherMetaData.publisher?.storeTabName]
  );

  const isProductMadness = useMemo(
    () =>
      [EBundlesInternalViewModel.JACKPOT_SLOTS, EBundlesInternalViewModel.BIG_FISH_CASINO].includes(
        publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel || ''
      ),
    [publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel]
  );

  const showOtpButton = useMemo(
    () =>
      playerAuthData.otpOn &&
      (isDesktop
        ? playerAuthData?.otpTextModel && playerAuthData?.otpTextModel !== EOTPTextModel.MOBILE_ONLY
        : true),
    [playerAuthData, isWebview, isDesktop]
  );

  return {
    API,
    publisherMetaData,
    isWebview,
    isProductMadness,
    showOtpButton,
    trackLoginEvent,
  };
};
