import { Box, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import newCheckoutConfetti from 'assets/animations/new-checkout-confetti.json';
import React, { useEffect, useMemo, useState } from 'react';
import useApi from 'hooks/useApi';
import { BootResponse, StoreThemeResponse } from 'constants/apiResponses.types';
import { OrderSet } from 'components/order-set/order-set';
import useCustomEvents from 'hooks/useCustomEvents';
import {
  ECheckoutPageEvent,
  EEventsType,
  EOfferType,
  EResultOptions,
  EStorePhase,
  ESupportType,
} from 'constants/enums';
import BackToGameButton from 'components/BackToGameButton/BackToGameButton';
import { getReturnToGameLinkAddress } from 'components/BackToGameButton/BackToGameButton.utils';
import { useTranslation } from 'react-i18next';
import { getPlatformData } from 'utils';
import CloseIcon from '@mui/icons-material/Close';
import '../style.scss';
import { StyledCloseIconWrapper, StyledHeader, StyledSubHeader } from './style';
import { useAppState } from 'state/hooks/app.state.hook';
import { useSupportButton } from 'hooks/useSupportButton';

interface ISuccessOrderProps {
  orderId: string;
  currencyCode: string;
}

export const SuccessOrder: React.FC<ISuccessOrderProps> = ({ orderId, currencyCode }) => {
  const { t } = useTranslation();
  const API = useApi({ orderId: orderId });
  const orders = API.getOrder;
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const theme = publisherMetaData?.storeTheme as StoreThemeResponse;
  const [returnToGameLinkAddress, setReturnToGameLinkAddress] = useState<string>();
  const navigate = useNavigate();
  const [animationDone, setAnimationDone] = useState(false);
  const referenceSize = '12px';
  const { setDisplayLoader, selectedOffer } = useAppState();
  const [showCloseButton, setShowCloseButton] = useState(false);
  const {
    headerColor,
    headerSize,
    headerWeight,
    textColor,
    textSize,
    textWeight,
    headerText,
    text,
  } = theme.completedScreen;

  const { openSupportButton } = useSupportButton({
    postLoginSnippet: publisherMetaData.supportConfiguration?.postLoginSnippet,
  });

  useEffect(() => {
    document.getElementById('root')?.classList.add('screen-lock');

    return () => {
      document.getElementById('root')?.classList.remove('screen-lock');
    };
  }, []);

  const shouldRemoveBackButtonOnFree = useMemo(() => {
    if (!publisherMetaData?.featureFlags) return;

    const isFreeOrder = orders?.data?.offer && orders.data.offer.price === 0;
    const isFeatureOn = publisherMetaData.featureFlags.store_remove_back_to_game_button_after_free;

    return isFreeOrder && isFeatureOn;
  }, [publisherMetaData?.featureFlags, orders?.data?.offer]);

  useEffect(() => {
    setDisplayLoader(false);

    customEvents.sendCustomEvent(
      EEventsType.COMPLETE_SCREEN_PRESENTED,
      {
        status: EResultOptions.SUCCESS,
        currency_code: currencyCode,
        platform: getPlatformData(),
        order_id: orderId,
      },
      EStorePhase.POST_LOGIN
    );
  }, []);

  useEffect(() => {
    if (!publisherMetaData?.featureFlags) return;

    if (
      !publisherMetaData.featureFlags.store_success_screen_hide_close_until_refetch ||
      selectedOffer?.offerType === EOfferType.ROLLING_OFFER
    ) {
      setShowCloseButton(true);
    } else {
      API.getOffers.refetch().then(() => {
        setShowCloseButton(true);
      });
    }
  }, [publisherMetaData.featureFlags?.store_success_screen_hide_close_until_refetch]);

  const backToStore = () => {
    const eventData = {
      event: ECheckoutPageEvent.BACK_TO_STORE,
      params: {
        isOrderSucceed: true,
        offer: API.getOrder.data.offer,
      },
    };
    window.postMessage(eventData, '*');
  };

  useEffect(() => {
    const deepLinks = publisherMetaData?.integration.deepLinks;
    if (deepLinks.length > 0) {
      const backToGameLink = getReturnToGameLinkAddress(deepLinks);
      setReturnToGameLinkAddress(backToGameLink);
    }
  }, [setReturnToGameLinkAddress, publisherMetaData]);

  const isBackToGameBtnShown =
    publisherMetaData?.integration.backToGameButtonOn &&
    publisherMetaData.storeTheme.completedScreen.backToGameButtonText &&
    returnToGameLinkAddress &&
    !shouldRemoveBackButtonOnFree;

  const contactSupportClicked = () => {
    customEvents.sendCustomEvent(
      EEventsType.SUPPORT_FORM_OPEN,
      {
        phase: EStorePhase.POST_ORDER,
        order_id: orderId,
      },
      EStorePhase.POST_LOGIN
    );
    if (publisherMetaData.supportConfiguration?.postLoginSnippet) {
      openSupportButton?.click();
      API.sendEmptySupport(
        publisherMetaData.storeTheme.publisherId,
        ESupportType.POST_LOGIN,
        EStorePhase.POST_ORDER,
        orderId
      );
    } else {
      orders.remove();
      navigate(`/support/${ESupportType.POST_LOGIN}/${EStorePhase.POST_LOGIN}`);
    }
  };

  return (
    <div className={'checkout-completion'}>
      <StyledCloseIconWrapper opacity={showCloseButton ? 1 : 0}>
        {showCloseButton && (
          <IconButton onClick={backToStore} size="small">
            <CloseIcon style={{ fontSize: 40, color: 'white' }} />
          </IconButton>
        )}
      </StyledCloseIconWrapper>
      {!animationDone && (
        <Lottie
          className="confetti-animation"
          animationData={newCheckoutConfetti}
          loop={false}
          onComplete={() => setAnimationDone(true)}
          style={{
            position: 'absolute',
          }}
        />
      )}
      <StyledHeader
        data-testid="storeResolveHeader"
        headerColor={headerColor}
        headerSize={headerSize}
        headerWeight={headerWeight}
      >
        {headerText}
      </StyledHeader>
      <StyledSubHeader
        data-testid="storeResolveDesc"
        textColor={textColor}
        textSize={textSize}
        textWeight={textWeight}
      >
        {text}
      </StyledSubHeader>
      <OrderSet products={orders.data.offer.products} showBackground={false} />
      {isBackToGameBtnShown && (
        <BackToGameButton
          useNewCheckout={true}
          btnText={publisherMetaData.storeTheme.completedScreen.backToGameButtonText}
          btnColor={publisherMetaData.storeTheme.general.buttonColor}
          width={'100%'}
          borderRadius={'4px'}
          returnToGameLinkAddress={returnToGameLinkAddress}
          marginTop={'20px'}
          marginBottom={'16px'}
        />
      )}
      <Box>
        <Button
          variant="text"
          fullWidth
          sx={{
            color: 'white',
            fontSize: '12px',
            margin: '20px 0',
          }}
          onClick={contactSupportClicked}
        >
          {t('contactSupport')}
        </Button>
      </Box>
      <div
        style={{
          color: publisherMetaData.storeTheme.completedScreen.textColor,
          fontSize: referenceSize,
          fontWeight: 400,
        }}
        className="fadein"
      >
        <span>{t('paymentReferenceID')}</span>
        <br />
        <span>{orderId}</span>
      </div>
    </div>
  );
};