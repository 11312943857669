import { useNewRelic } from '../components/NewRelicProvider';
import { localStorageUtil } from 'state/localStorage.state.service';
import { ELocalStorageKeys } from '../constants/enums';
import { isAxiosError } from 'axios';

export default function useFEMonitoring() {
  const { newRelicBrowserAgent } = useNewRelic();
  const logToNewRelic = async (error: any) => {
    try {
      const playerData = JSON.parse(localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA) || '{}');

      if (newRelicBrowserAgent?.log) {
        let errorDetails: any = { message: 'Unknown error' };
        if (isAxiosError(error)) {
          errorDetails = {
            message: error?.message || 'Unknown error',
            url: error?.config?.url || 'Unknown URL',
            method: error?.config?.method || 'Unknown Method',
            requestHeaders: error?.config?.headers
              ? JSON.stringify(error?.config?.headers)
              : 'No Request Headers',
            requestData: error?.config?.data
              ? JSON.stringify(error?.config?.data)
              : 'No Request Data',
          };
          if (error?.response) {
            errorDetails = {
              ...errorDetails,
              status: error?.response?.status || 'Unknown Status',
              statusText: error?.response?.statusText || 'Unknown Status Text',
              responseData: error?.response?.data
                ? JSON.stringify(error?.response?.data)
                : 'No Response Data',
            }
          }
        } else {
          errorDetails = JSON.stringify(error);
        }

        const customAttributes = {
          playerCountry: playerData?.playerCountry || 'Unknown Country',
          playerId: playerData?.playerId || 'Unknown ID',
          playerName: playerData?.playerName || 'Unknown Name',
          hasTrace: true,
          level: 'error',
          environment: process.env.NODE_ENV || 'Unknown Environment',
        };

        newRelicBrowserAgent.log(errorDetails.message, {
          customAttributes: {
            ...customAttributes,
            ...errorDetails,
          },
        });
      } else {
        console.error('Error logged:', {
          message: error?.message || error.toString(),
          playerData,
        });
      }
    } catch (logError) {
      console.error('Failed to log error to New Relic:', logError);
    }
  };

  return {
    logToNewRelic,
  };
}
