import { useEffect, useState } from 'react';
import { SUPPORT_IFRAME_LAUNCHER } from 'pages/Shop/utils';

export const useSupportButton = ({ postLoginSnippet }: { postLoginSnippet?: string }) => {
  const [openSupportButton, setOpenSupportButton] = useState<HTMLButtonElement | null>(null);

  const getIframeOpenChatButton = () => {
    const iframe = document.getElementById(SUPPORT_IFRAME_LAUNCHER);

    if (iframe) {
      const button = (iframe as HTMLIFrameElement).contentDocument?.querySelector('button');
      return button;
    }

    return null;
  };

  useEffect(() => {
    if (!postLoginSnippet) return;

    const interval = setInterval(() => {
      const openChatButton = getIframeOpenChatButton();
      if (openChatButton) {
        clearInterval(interval);
        setOpenSupportButton(openChatButton as HTMLButtonElement);
      }
    }, 100);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [postLoginSnippet]);

  return { openSupportButton };
};
