import { ActionButton } from '@appcharge/shared-ui';
import { Box, Button, CircularProgress, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import useCustomEvents from '../../hooks/useCustomEvents';
import { SupportData } from '../../constants/support.types';
import { EStorePhase, ESupportType, EEventsType } from '../../constants/enums';
import { isMobile } from 'react-device-detect';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'state/hooks/app.state.hook';

const Support = () => {
  const API = useApi({});
  const { t } = useTranslation();
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const navigate = useNavigate();
  const { setBackgroundProps } = useAppState();
  const {
    supportType = ESupportType.POST_LOGIN,
    orderId,
    storePhase = undefined,
  }: {
    supportType?: ESupportType;
    orderId?: string;
    storePhase?: EStorePhase;
  } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [personalNote, setPersonalNote] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [formDetails, setFormDetails] = useState<SupportData>({
    publisherId: publisherMetaData.storeTheme.publisherId,
    supportFullName: '',
    communicationType: 'email',
    communicationDetails: '',
    supportRequestReason: 'N/A',
    playerStorePhase: storePhase || 'N/A',
    playerMessage: '',
    orderId: orderId || '',
  });
  const [isSent, setIsSent] = useState(false);
  const [reason, setReason] = useState('no_reason_specified');
  const isLoggedIn = supportType === ESupportType.POST_LOGIN;

  useEffect(() => {
    if (!publisherMetaData?.storeTheme) return;

    setBackgroundProps({
      backgroundImageMobile: publisherMetaData.storeTheme.general.backgroundImageMobile,
      backgroundImageDesktop: publisherMetaData.storeTheme.general.backgroundImageDesktop,
      minHeight: '100vh',
      height: '',
    });
  }, [publisherMetaData?.storeTheme]);

  const handleErrors = (): boolean => {
    if (formDetails.supportFullName.trim() === '') {
      setIsError(true);
      setErrorMsg(t('support.fullNameIsMandatory'));
      setTimeout(() => {
        setIsError(false);
      }, 2000);
      return false;
    }
    if (email === '') {
      setIsError(true);
      setErrorMsg(t('support.emailIsMandatory'));
      setTimeout(() => {
        setIsError(false);
      }, 2000);
      return false;
    }
    if (email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) === null) {
      setIsError(true);
      setErrorMsg(t('support.enterValidEmail'));
      setTimeout(() => {
        setIsError(false);
      }, 2000);
      return false;
    }
    if (reason === 'no_reason_specified') {
      setIsError(true);
      setErrorMsg(t('support.selectReasonToContactUs'));
      setTimeout(() => {
        setIsError(false);
      }, 2000);
      return false;
    }
    if (personalNote === '') {
      setIsError(true);
      setErrorMsg(t('support.enterPersonalNote'));
      setTimeout(() => {
        setIsError(false);
      }, 2000);
      return false;
    }
    return true;
  };

  const sendSupport = () => {
    setIsLoading(true);
    if (!API.getPublisherMeta.isLoading && publisherMetaData) {
      API.sendSupport
        .mutateAsync({ data: formDetails, supportType })
        .then(() => {
          customEvents.sendCustomEvent(
            EEventsType.SUPPORT_FORM_SUBMIT,
            {
              order_id: orderId,
              phase: storePhase,
              reason: formDetails.supportRequestReason,
            },
            isLoggedIn ? EStorePhase.POST_LOGIN : EStorePhase.PRE_LOGIN
          );
          setIsLoading(false);
          setIsSent(true);
        })
        .catch((err) => {
          console.log(err);
          customEvents.sendCustomEvent(
            EEventsType.ERROR,
            {
              type: 'api error',
              route: 'sendSupport',
              error: JSON.stringify(err),
            },
            EStorePhase.POST_LOGIN
          );
        });
    }
  };

  return (
    <Stack
      className="contact-form"
      direction="column"
      p={2}
      alignItems="center"
      justifyContent={isMobile ? 'flex-start' : 'center'}
    >
      {!isSent ? (
        <>
          <h1
            style={{
              fontFamily: publisherMetaData?.storeTheme.general.font,
              color: publisherMetaData?.storeTheme.login.textColor,
              padding: '0px',
              margin: '0px',
              width: '100%',
              marginBottom: '16px',
            }}
          >
            {t('support.support')}
          </h1>
          <TextField
            id="outlined-basic"
            label={null}
            variant="outlined"
            disabled={isLoading}
            placeholder={t('support.fullName')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '2px',
              width: '100%',
              '& input': {
                fontSize: '1rem',
              },
              marginBottom: '16px',
            }}
            name="name"
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                supportFullName: e.target.value,
              })
            }
          />
          <TextField
            id="outlined-basic"
            label={null}
            variant="outlined"
            placeholder={t('support.email')}
            disabled={isLoading}
            required
            sx={{
              backgroundColor: 'white',
              borderRadius: '2px',
              width: '100%',
              '& input': {
                fontSize: '1rem',
              },
              marginBottom: '16px',
            }}
            value={email}
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
              setFormDetails({
                ...formDetails,
                communicationDetails: e.target.value,
              });
            }}
          />
          <Select
            placeholder={t('support.tellUsWhatHappened')}
            value={reason}
            displayEmpty={true}
            disabled={isLoading}
            sx={{
              backgroundColor: 'white',
              borderRadius: '2px',
              width: '100%',
              '& .MuiSelect-select': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
              },
              marginBottom: '16px',
            }}
            onChange={(e) => {
              setReason(e.target.value);
              setFormDetails({
                ...formDetails,
                supportRequestReason: e.target.value,
              });
            }}
          >
            <MenuItem value={'no_reason_specified'} sx={{ fontSize: '1rem' }} selected disabled>
              <em>{t('support.tellUsWhatHappened')}</em>
            </MenuItem>
            <MenuItem value="balance_not_updating" sx={{ fontSize: '1rem' }}>
              {t('support.balanceIsNotUpdating')}
            </MenuItem>
            <MenuItem value="payment_failed" sx={{ fontSize: '1rem' }}>
              {t('support.paymentFailed')}
            </MenuItem>
            <MenuItem value="other" sx={{ fontSize: '1rem' }}>
              {t('support.other')}
            </MenuItem>
          </Select>
          <TextField
            id="outlined-basic"
            label={null}
            disabled={isLoading}
            variant="outlined"
            value={personalNote}
            placeholder={t('support.personalNote')}
            multiline={true}
            rows={4}
            sx={{
              backgroundColor: 'white',
              borderRadius: '2px',
              width: '100%',
              '& textarea': {
                fontSize: '1rem',
              },
              marginBottom: '16px',
            }}
            onChange={(e) => {
              setPersonalNote(e.target.value);
              setFormDetails({
                ...formDetails,
                playerMessage: `${reason}: ${e.target.value}`,
              });
            }}
          />
          {isError && (
            <div
              style={{
                color: '#721c24',
                backgroundColor: '#f8d7da',
                padding: '10px',
                borderRadius: '4px',
                border: '1px solid #f5c6cb',
                width: '100%',
                fontFamily: 'Roboto',
                fontSize: '12px',
              }}
            >
              {errorMsg}
            </div>
          )}
          {!isLoading ? (
            <>
              <ActionButton
                text={t('support.send')}
                action={() => {
                  handleErrors() && !isLoading && sendSupport();
                }}
                font={`'Roboto', sans-serif`}
                width={'100%'}
                colors={publisherMetaData.storeTheme.general.buttonColor}
              />

              <Box className="back-button" mt={4}>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    customEvents.sendCustomEvent(
                      EEventsType.SUPPORT_FORM_CANCEL,
                      {
                        order_id: orderId,
                        phase: storePhase,
                      },
                      isLoggedIn ? EStorePhase.POST_LOGIN : EStorePhase.PRE_LOGIN
                    );
                    navigate(-1);
                  }}
                >
                  {t('support.back')}
                </Button>
              </Box>
            </>
          ) : (
            <Box py={5}>
              <CircularProgress sx={{ color: 'white' }} />
            </Box>
          )}
        </>
      ) : (
        <>
          <Stack
            style={{
              height: 'calc(100vh - 4em)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1
              style={{
                fontFamily: publisherMetaData.storeTheme.general.font,
                color: publisherMetaData.storeTheme.login.textColor,
                padding: '0px',
                margin: '0px',
                width: '100%',
                textAlign: 'center',
                fontSize: '1.5rem',
                marginBottom: '16px',
              }}
            >
              {t('support.weWillGetBackSoon')}
            </h1>

            <ActionButton
              text={t('support.backToShop')}
              action={() => {
                navigate('../');
              }}
              colors={publisherMetaData.storeTheme.general.buttonColor}
              font={publisherMetaData.storeTheme.general.font}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Support;
