import { ActionButton, Gradient } from '@appcharge/shared-ui';
import { sendMessageToIframeParent } from '../../utils';
import { ECheckoutPageEvent } from '../../constants/enums';

interface BackToGameButtonProps {
  btnText: string;
  btnColor: Gradient;
  useNewCheckout: boolean;
  returnToGameLinkAddress: string;
  border?: string;
  borderRadius?: string;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
}

const BackToGameButton: React.FC<BackToGameButtonProps> = ({
  btnText,
  btnColor,
  returnToGameLinkAddress,
  border,
  useNewCheckout,
  borderRadius,
  width,
  marginTop,
  marginBottom,
}) => {
  const returnToGame = () => {
    if (useNewCheckout) {
      sendMessageToIframeParent(ECheckoutPageEvent.BACK_TO_GAME, {
        returnToGameLinkAddress,
      });
    } else {
      window.location.assign(returnToGameLinkAddress);
    }
  };
  return (
    <ActionButton
      text={btnText}
      action={returnToGame}
      colors={btnColor}
      border={border}
      borderRadius={borderRadius}
      width={width}
      marginTop={marginTop}
      marginBottom={marginBottom}
    />
  );
};

export default BackToGameButton;
