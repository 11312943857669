import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledProductsAnimationWrapper = styled(motion.div)`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1400;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  gap: 20px;
`;

export const StyledProductsWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  max-width: 70%;
  flex-wrap: wrap;
  justify-content: center;

  img {
    width: 150px;
    height: auto;
  }
`;
