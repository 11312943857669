import { useCallback, useEffect, useMemo, useState } from 'react';
import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import Typography from '@mui/material/Typography';
import './style.scss';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  EBundlesInternalViewModel,
  EEventsType,
  EFeatureFlag,
  ELocalStorageKeys,
  EStorePhase,
  ESupportType,
} from '../../constants/enums';
import useCustomEvents from '../../hooks/useCustomEvents';
import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as AppStore } from './images/AppStoreBlack.svg';
import { ReactComponent as GooglePlay } from './images/GooglePlayBlack.svg';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import pmLogo from './images/product-madness-logo.png';
import aristocratLogo from './images/aristocrat-logo.png';
import { useLocalizationState } from 'state/hooks/localization.state.hook';
import { loginPageService } from '../../pages/login/login-page.service';
// import { useSupportButton } from 'hooks/useSupportButton';
// import { SUPPORT_IFRAME, SUPPORT_IFRAME_LAUNCHER } from 'pages/Shop/utils';
import { localStorageUtil } from 'utils';
import { StyledLogoContainer } from './style';
import { useAppState } from 'state/hooks/app.state.hook';

const privacyPolicyLinkProductMadness = 'https://www.productmadness.com/privacy-notice';
const termsAndConditionsLinkProductMadness = 'https://www.productmadness.com/terms-of-service';

export const Footer: React.FC = () => {
  const [isLoginPage, setIsLoginPage] = useState<boolean>(false);
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const navigate = useNavigate();
  const { openInfoModal } = useAppState();
  const customEvents = useCustomEvents();
  const [isProductMadness, setIsProductMadness] = useState(false);
  const [isOldVersion, setIsOldVersion] = useState(false);

  const footerSettings = useMemo(
    () => publisherMetaData.storeTheme.general?.footer,
    [publisherMetaData.storeTheme.general?.footer]
  );

  const logoImageSrc = isMobile ? footerSettings.imageMobile : footerSettings.imageDesktop;

  useEffect(() => {
    if (localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)) {
      setIsLoginPage(false);
    } else {
      setIsLoginPage(true);
    }
  }, [localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)]);

  // const { openSupportButton } = useSupportButton({
  //   postLoginSnippet: publisherMetaData.supportConfiguration?.postLoginSnippet,
  // });
  // useEffect(() => {
  //   const checkIframeVisibility = () => {
  //     const iframe = document.getElementById('hs-web-sdk-iframe');
  //     if (iframe && window.getComputedStyle(iframe).visibility === 'visible') {
  //       return true;
  //     }
  //     return false;
  //   };

  //   const loadSupportScript = () => {
  //     if (document.getElementById('postlogin-support-script')) {
  //       document.getElementById('postlogin-support-script')?.remove();
  //     }
  //     const scriptUrl = publisherMetaData.supportConfiguration?.postLoginSnippet;
  //     const alreadyExists =
  //       checkIframeVisibility() ||
  //       (scriptUrl && !!document.querySelector(`script[src="${scriptUrl}"]`));

  //     if (!scriptUrl || alreadyExists || isLoginPage) {
  //       setIsScriptLoaded(true);
  //       return;
  //     }

  //     const script = document.createElement('script');
  //     script.src = scriptUrl;
  //     script.id = 'postlogin-support-script';
  //     script.onload = () => {
  //       const helpshift = window as any;
  //       if (helpshift.Helpshift && typeof helpshift.Helpshift === 'function') {
  //         helpshift.Helpshift('init');
  //       }
  //       const intervalId = setInterval(() => {
  //         const didLoadSupportIframe = checkIframeVisibility();
  //         if (didLoadSupportIframe) {
  //           setIsScriptLoaded(true);
  //           clearInterval(intervalId);
  //         }
  //       }, 100);
  //     };
  //     document.body.appendChild(script);

  //     return script;
  //   };

  //   loadSupportScript();

  //   return () => {
  //     const supportButton = document.getElementById(SUPPORT_IFRAME);
  //     if (supportButton) {
  //       supportButton.remove();
  //     }
  //     const supportIframe = document.getElementById(SUPPORT_IFRAME_LAUNCHER);
  //     if (supportIframe) {
  //       supportIframe.remove();
  //     }
  //     const supportScript = document.querySelector(
  //       `script[src="${publisherMetaData?.supportConfiguration?.postLoginSnippet}"]`
  //     );
  //     if (supportScript) {
  //       supportScript.remove();
  //     }
  //     const middlewareScripts = document.querySelectorAll('script[src]');

  //     const scriptUrlPattern = 'https://webchat.helpshift.com/latest';
  //     middlewareScripts.forEach((s) => {
  //       if ((s as HTMLScriptElement).src.includes(scriptUrlPattern)) {
  //         s.remove();
  //       }
  //     });
  //   };
  // }, [publisherMetaData?.supportConfiguration?.postLoginSnippet, location, isLoginPage]);

  useEffect(() => {
    if (loginPageService.checkIfOlderOsVersions()) {
      setIsOldVersion(true);
    }
  }, []);

  const showAppchargeLogo = footerSettings?.showAppchargeLogo ?? true;

  const { t } = useTranslation();

  const { languagesList } = useLocalizationState();
  const supportMultiLanguageEnabled = publisherMetaData.featureFlags.store_multi_language_support;

  const getFooterVersion = useCallback(() => {
    if (!process.env.REACT_APP_STORE_VERSION || !process.env.REACT_APP_SHARED_UI_VERSION) {
      return '';
    }
    const version =
      process.env.REACT_APP_STORE_VERSION.replace('HF', '') +
        process.env.REACT_APP_SHARED_UI_VERSION?.replace('HF', '') || '';
    return version ? `v${version}` : '';
  }, [publisherMetaData]);

  useEffect(() => {
    setIsProductMadness(
      [
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.BIG_FISH_CASINO,
        EBundlesInternalViewModel.CASHMAN_CASINO,
        EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
        EBundlesInternalViewModel.MIGHTY_FU_CASINO,
        EBundlesInternalViewModel.HEART_OF_VEGAS,
      ].includes(publisherMetaData.storeTheme.general.bundlesInternalViewModel)
    );
  }, [publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel]);

  const handleSupportClick = () => {
    // if (!isScriptLoaded) {
    //   return;
    // }

    const phase: EStorePhase = isLoginPage ? EStorePhase.PRE_LOGIN : EStorePhase.POST_LOGIN;
    const supportType: ESupportType = isLoginPage
      ? ESupportType.PRE_LOGIN
      : ESupportType.POST_LOGIN;

    customEvents.sendCustomEvent(
      EEventsType.SUPPORT_FORM_OPEN,
      {
        phase,
        supportModel: publisherMetaData.supportConfiguration?.supportModel,
      },
      phase
    );
    if (
      footerSettings.supportLink &&
      (!publisherMetaData.supportConfiguration.postLoginSnippet || isLoginPage)
    ) {
      if (isLoginPage) {
        window.location.href = footerSettings.supportLink;
      } else {
        window.open(footerSettings.supportLink, '_blank');
      }
      return;
    }
    if (publisherMetaData.supportConfiguration?.postLoginSnippet && !isLoginPage) {
      (window as any).Helpshift('open');
      const url = new URL(window.location.href);
      url.searchParams.set('support', 'true');
      window.history.pushState({}, '', url);

      API.sendEmptySupport(publisherMetaData.storeTheme.publisherId, supportType, phase);
    } else {
      navigate(`/support/${supportType}/${phase}`);
    }
  };

  const openInNewTab = isProductMadness || footerSettings?.openLinksInNewTab || false;

  const shouldDisplayLanguagePicker =
    supportMultiLanguageEnabled && languagesList && languagesList?.length > 1;

  const showVersion = useMemo(
    () => publisherMetaData.featureFlags[EFeatureFlag.STORE_SHOW_VERSION],
    [publisherMetaData.featureFlags]
  );

  return (
    <div className={'shop-footer'}>
      <div
        className={`footerContainer ${publisherMetaData.storeTheme.general.bundlesInternalViewModel}`}
      >
        {isProductMadness && !isLoginPage && (
          <Stack maxWidth="400px" minWidth="320px" mb={5}>
            <Box mb={2}>
              <Typography
                color="white"
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '11px',
                  fontWeight: '500',
                  textAlign: 'center',
                  lineHeight: '150%',
                }}
              >
                {t('footer.byClicking')}{' '}
                <a href={privacyPolicyLinkProductMadness} target="_blank" rel="noreferrer">
                  {t('footer.privacyPolicy')}
                </a>{' '}
                {t('and')}{' '}
                <a href={termsAndConditionsLinkProductMadness} target="_blank" rel="noreferrer">
                  {t('footer.termsAndConditionsLong')}
                </a>
              </Typography>
            </Box>
            <Typography
              color="white"
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '11px',
                fontWeight: '500',
                textAlign: 'center',
                lineHeight: '150%',
              }}
            >
              {t('footer.legalOver18')}
            </Typography>
            <div className={'pm-footer-logos'}>
              <img src={pmLogo} alt="pm-logo" />
              <img src={aristocratLogo} alt="aristocrat-logo" />
            </div>
          </Stack>
        )}
        <Stack
          direction="row-reverse"
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'relative'}
          width={'100%'}
        >
          {logoImageSrc && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <img className="publisherLogo" src={logoImageSrc} alt="logo" />
            </Stack>
          )}
          {footerSettings.appstoreLink && footerSettings.googlePlayLink && (
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="center"
              alignItems="center"
              gap={'4px'}
            >
              <a href={footerSettings.appstoreLink} target="_blank" rel="noopener noreferrer">
                <AppStore />
              </a>
              <a href={footerSettings.googlePlayLink} target="_blank" rel="noopener noreferrer">
                <GooglePlay />
              </a>
            </Stack>
          )}
          <span className="footer-divider"></span>
        </Stack>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          width={'100%'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mb={'12px'}
          mt={'12px'}
          gap={isMobile ? 2 : 0}
        >
          <StyledLogoContainer
            direction={isMobile ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            className="logo-footer-container"
            gap={1}
            marginTop={isOldVersion ? 2 : 0}
            setLength={showVersion}
          >
            {showAppchargeLogo && <Logo />}
            {showVersion && (
              <span
                style={{
                  textAlign: isMobile ? 'center' : 'left',
                }}
                className="footer-version"
              >
                {getFooterVersion()}
              </span>
            )}
          </StyledLogoContainer>
          <Stack direction="column" gap={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              marginRight={2}
              width={'100%'}
              gap={1}
              sx={{ flexWrap: 'wrap' }}
            >
              <div className="footer-section">
                <Typography
                  data-testid={'footerPrivacyPolicy'}
                  variant="subtitle1"
                  textAlign="center"
                  fontSize={14}
                  onClick={() => {
                    openInNewTab
                      ? window.open(footerSettings.privacyPolicyUrl, '_blank')
                      : openInfoModal(footerSettings.privacyPolicyUrl);
                  }}
                >
                  {t('footer.privacy')}
                </Typography>
              </div>
              <span className="quadraticDot"></span>
              <div className="footer-section">
                <Typography
                  data-testid={'footerTermsAndConditions'}
                  variant="subtitle1"
                  textAlign="center"
                  fontSize={14}
                  onClick={() => {
                    openInNewTab
                      ? window.open(footerSettings.termsAndConditionsUrl, '_blank')
                      : openInfoModal(footerSettings.termsAndConditionsUrl);
                  }}
                >
                  {t('footer.termsAndConditions')}
                </Typography>
              </div>
              <span className="quadraticDot"></span>
              <div className="footer-section">
                <Typography
                  data-testid={'footerSupport'}
                  variant="subtitle1"
                  textAlign="center"
                  fontSize={14}
                  // style={{
                  //   pointerEvents: isScriptLoaded ? 'auto' : 'none',
                  //   opacity: isScriptLoaded ? 1 : 0.5,
                  // }}
                  onClick={handleSupportClick}
                >
                  {t('footer.support')}
                </Typography>
              </div>
              {shouldDisplayLanguagePicker && (
                <>
                  <span className="quadraticDot"></span>
                  <div className="footer-section">
                    <LanguagePicker fontSize={14} />
                  </div>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};
