import React, { useEffect } from 'react';
import { BootResponse } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';
import { useAppState } from 'state/hooks/app.state.hook';

export const Maintenance: React.FC = () => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { setBackgroundProps } = useAppState();

  const images = [
    {
      src: `${process.env.PUBLIC_URL}/assets/images/giraff.png`,
      class: 'giraff',
    },
  ];

  useEffect(() => {
    if (!publisherMetaData?.storeTheme) return;

    setBackgroundProps({
      backgroundImageMobile: publisherMetaData.storeTheme.general.backgroundImageMobile,
      backgroundImageDesktop: publisherMetaData.storeTheme.general.backgroundImageDesktop,
    });
  }, [publisherMetaData?.storeTheme]);

  return (
    <div
      id="static"
      style={{
        fontFamily: publisherMetaData.storeTheme.general.font,
      }}
    >
      {images?.map((img, i) => <img key={i} alt={img.class} className={img.class} src={img.src} />)}
    </div>
  );
};
