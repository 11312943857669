import { Box } from '@mui/material';
import { useIsDesktop, useIsLandscape } from '@appcharge/shared-ui';
import '../../style.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { loginPageService } from '../../login-page.service';
import { useTranslation } from 'react-i18next';
import { WebviewEscapeScreen } from '../../WebviewEscapeScreen/WebviewEscapeScreen';
import { LoginTermsBlock } from '../../TermsBlocks/TermsBlock';
import { EBundlesInternalViewModel, ELoginMode, EOTPTextModel } from '../../../../constants/enums';
import { LoginButtonBlocks } from '../../Components/ButtonBlocks';
import { useLoginPage } from '../../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';
import { useAppState } from 'state/hooks/app.state.hook';
import { ConsentAlert } from 'pages/login/TermsBlocks/ConsentAlert';

const saleTextImage = 'https://media.appcharge.com/media/login-images/sale-text-10%25.png';
const mobileInfoJackpotSlots =
  'https://media.appcharge.com/media/login-images/JMS-how_does_it_work2.png';
const mobileInfoBigFish =
  'https://media.appcharge.com/media/login-images/BFC-infographic-mobile3.png';
const JMS_desktopLogo = 'https://media.appcharge.com/media/login-images/JMS_login-logo-desk.png';
const BFC_desktopLogo = 'https://media.appcharge.com/media/login-images/BFC_login-logo-desk.png';
const privacyPolicyLinkCustom = 'https://www.productmadness.com/privacy-notice';
const termsAndConditionsLinkCustom = 'https://www.productmadness.com/terms-of-service';

export const ProductMadnessLogin: React.FC = () => {
  const { publisherMetaData, isWebview } = useLoginPage();
  const { handleBackBtnClicked, mode } = useLoginState();
  const textColor = publisherMetaData.storeTheme.login.textColor;
  const logo =
    publisherMetaData.storeTheme.login.loginLogoImage || publisherMetaData.storeTheme.general.logo;
  const gameName = publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel;
  const isDesktop = useIsDesktop();
  const { setBackgroundProps } = useAppState();

  const { t } = useTranslation();
  const [isOldVersion, setIsOldVersion] = useState<string>('');
  const isLandscape = useIsLandscape();
  const desktopLogo =
    gameName === EBundlesInternalViewModel.JACKPOT_SLOTS ? JMS_desktopLogo : BFC_desktopLogo;

  const playerAuthData = publisherMetaData.integration.playersAuthentication;

  const mobileHowDoesItWorkImage =
    gameName === EBundlesInternalViewModel.JACKPOT_SLOTS
      ? mobileInfoJackpotSlots
      : mobileInfoBigFish;

  useEffect(() => {
    if (loginPageService.checkIfOlderOsVersions()) {
      setIsOldVersion('ac-login-page--ios13');
    }
  }, []);

  const showOtpButton = useMemo(
    () =>
      playerAuthData.otpOn &&
      (isDesktop
        ? playerAuthData?.otpTextModel && playerAuthData?.otpTextModel !== EOTPTextModel.MOBILE_ONLY
        : true),
    [playerAuthData]
  );

  useEffect(() => {
    if (!publisherMetaData?.storeTheme) return;

    const otpProductMadness = mode === ELoginMode.OTP;
    const loginBgColor =
      publisherMetaData.storeTheme.general.bundlesInternalViewModel ===
      EBundlesInternalViewModel.JACKPOT_SLOTS
        ? '#220E3C'
        : '#060D3B';

    setBackgroundProps({
      height: '',
      minHeight: '100vh',
      width: isDesktop || isLandscape ? '100%' : '100vw',
      backgroundImageMobile:
        publisherMetaData.storeTheme.login.loginBackgroundImageMobile ||
        publisherMetaData.storeTheme.general.backgroundImageMobile,
      backgroundImageDesktop:
        (otpProductMadness
          ? publisherMetaData.storeTheme.general.backgroundImageDesktop
          : publisherMetaData.storeTheme.login.loginBackgroundImageDesktop) ||
        publisherMetaData.storeTheme.general.backgroundImageDesktop,
      backgroundPosition: isDesktop ? 'center bottom' : 'center top',
      backgroundSize: 'cover',
      backgroundColor: loginBgColor,
    });
  }, [isDesktop, isLandscape, mode, publisherMetaData?.storeTheme]);

  return (
    <>
      <div
        className={`ac-login-page ac-login-page--jackpot-slots ${isOldVersion}`}
        style={{
          color: textColor,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={'ac-login-page__top-container'}
        >
          <ConsentAlert />
          <div className={'ac-login-page__logo'}>
            <img alt="game logo" src={isDesktop || isLandscape ? desktopLogo : logo}></img>
          </div>
          {isWebview && !showOtpButton ? (
            <WebviewEscapeScreen />
          ) : (
            <>
              {mode === ELoginMode.PROVIDERS && (
                <div className={'ac-login-page__sale-image'}>
                  <img src={saleTextImage} alt={'sale-image'}></img>
                </div>
              )}
              <LoginButtonBlocks maxWidth={'320px'} />
              {mode !== ELoginMode.PROVIDERS && (
                <button className="backButton" onClick={() => handleBackBtnClicked()}>
                  {t('login.back')}
                </button>
              )}
            </>
          )}
        </Box>
      </div>
      <div className={'ac-login-page__footer-container ' + gameName}>
        {!(isDesktop || isLandscape) && mode === ELoginMode.PROVIDERS && (
          <img
            className="ac-login-page__footer-container__image"
            src={mobileHowDoesItWorkImage}
            alt={'infographic'}
          />
        )}
        <div className={`ac-login-page__footer-container__footer-text ${gameName}`}>
          <LoginTermsBlock
            textSize={'12px'}
            privacyPolicyLink={privacyPolicyLinkCustom}
            termsAndConditionsLink={termsAndConditionsLinkCustom}
          />
        </div>
      </div>
    </>
  );
};
