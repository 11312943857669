import { BootResponse, OfferData, SectionData } from '../../../constants/apiResponses.types';
import useApi from '../../../hooks/useApi';
import {
  BagelCodeSection,
  BundlesLayout,
  DefaultSection,
  PipaSection,
  WhispersSection,
  LLCSection,
  MightyFuSection,
  GhostSection,
} from '@appcharge/shared-ui';
import { EConfigs, ESectionsViewModel } from 'constants/enums';
import { Bundle } from '../../../components/Bundle/Bundle';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../style.scss';
import { getTemplateProperty } from '../utils';

const sectionDesignDic = {
  [ESectionsViewModel.DEFAULT]: DefaultSection,
  [ESectionsViewModel.BAGEL_CODE]: BagelCodeSection,
  [ESectionsViewModel.PIPA]: PipaSection,
  [ESectionsViewModel.Whispers]: WhispersSection,
  [ESectionsViewModel.LLC]: LLCSection,
  [ESectionsViewModel.MIGHTY_FU_CASINO]: MightyFuSection,
  [ESectionsViewModel.GHOST]: GhostSection,
};

interface IBundlesListProps {
  selectOffer: (offer: OfferData) => void;
}

export const BundlesWrapper: React.FC<IBundlesListProps> = ({ selectOffer }) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesViewModel, sectionViewModel, bundlesInternalViewModel } =
    publisherMetaData.storeTheme.general;
  const sections = API.getOffers?.data?.sections as SectionData[];

  const SectionDesign = sectionDesignDic[sectionViewModel || ESectionsViewModel.DEFAULT];
  const [bundlesBySection, setBundlesBySection] = useState({} as Record<string, OfferData[]>);

  const [bundlesWithoutSections, setBundlesWithoutSections] = useState([] as OfferData[]);

  useEffect(() => {
    if (!API.getOffers?.data) return;

    const newBundleBySection: Record<string, OfferData[]> = {};
    const newBundlesWithoutSections: OfferData[] = [];

    API.getOffers?.data?.offers?.bundles?.forEach((bundle: OfferData) => {
      const sectionId = bundle.offerSectionId || 'no-section';
      const targetArray =
        sectionId === 'no-section'
          ? newBundlesWithoutSections
          : (newBundleBySection[sectionId] ||= []);
      targetArray.push(bundle);
    });

    // Order bundles according to sections
    const orderedBundles = Object.fromEntries(
      sections
        .filter((section) => newBundleBySection[section.sectionId]?.length)
        .map((section) => [section.sectionId, newBundleBySection[section.sectionId]])
    );
    setBundlesBySection(orderedBundles);
    setBundlesWithoutSections(newBundlesWithoutSections);
  }, [API.getOffers?.data]);

  const mapBundlesToLayout = (bundles: OfferData[]) => {
    if (bundles.length === 0) return null;
    return (
      <BundlesLayout
        bundlesViewModel={bundlesViewModel}
        bundlesInternalViewModel={publisherMetaData.storeTheme.general.bundlesInternalViewModel}
        gapX={getTemplateProperty(EConfigs.gapX, bundlesInternalViewModel)}
        gapY={getTemplateProperty(EConfigs.gapY, bundlesInternalViewModel)}
        maxWidthOverride={getTemplateProperty(EConfigs.maxWidthOverride, bundlesInternalViewModel)}
        desktopMaxWidth={getTemplateProperty(EConfigs.desktopMaxWidth, bundlesInternalViewModel)}
        marginTop={getTemplateProperty(EConfigs.marginTop, bundlesInternalViewModel)}
      >
        {bundles.map((bundle: OfferData, index: number) => (
          <div key={index} className={'bundle'}>
            <Bundle
              index={index}
              key={bundle.offerId}
              data={bundle}
              selectOffer={() => selectOffer(bundle)}
            />
          </div>
        ))}
      </BundlesLayout>
    );
  };

  return (
    <Box className={`bundles-wrapper ${bundlesInternalViewModel}`}>
      {bundlesBySection &&
        Object.entries(bundlesBySection).map(([sectionId, sectionBundles], index) => {
          const currentSection = sections?.find((item: any) => item?.sectionId === sectionId);
          return (
            <Box
              sx={{
                width: '100%',
                display: 'inline-flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              key={index}
            >
              {currentSection && (
                <SectionDesign
                  sectionName={currentSection.name}
                  image={currentSection.image || ''}
                />
              )}
              {mapBundlesToLayout(sectionBundles)}
            </Box>
          );
        })}

      <Box
        sx={{
          width: '100%',
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/*Make sure bundles without sections will be at the bottom of the page*/}
        {mapBundlesToLayout(bundlesWithoutSections)}
      </Box>
    </Box>
  );
};
