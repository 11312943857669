import axios from 'axios';
import { ELocalStorageKeys } from '../constants/enums';
import { localStorageUtil } from 'state/localStorage.state.service';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export default function useAxios() {
  const post = async <T = any>(
    path: string,
    { data, headers }: { data: any; headers: any },
    noToken = false
  ) => {
    const token = localStorageUtil.getAny(ELocalStorageKeys.SESSION_TOKEN);
    return await axios<T>({
      url: path,
      method: 'POST',
      headers: {
        ...headers,
        Authorization: !noToken ? `Bearer ${token}` : undefined,
      },
      data,
    }).catch((err) => {
      throw err;
    });
  };

  const get: (path: string, noToken?: boolean, params?: any) => Promise<any> = async (
    path,
    noToken = false,
    params
  ) => {
    const token = localStorageUtil.getAny(ELocalStorageKeys.SESSION_TOKEN);
    const response = await axios({
      url: path,
      method: 'GET',
      headers: {
        Authorization: !noToken ? `Bearer ${token}` : undefined,
      },
      params: params || undefined,
    });
    return response.data;
  };

  return { post, get };
}