import React, { useEffect, useMemo } from 'react';
import { StyledConsentHtml, StyledConsentSection } from '../style';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';
import { ELocalStorageKeys } from '../../../constants/enums';
import DOMPurify from 'dompurify';
import { Checkbox } from '@mui/material';

interface IAdvancedTermsProps {
  textColor?: string;
}

const CONSENT_SECTION_CLASS_NAME = 'consent-section';

export const AdvancedTerms: React.FC<IAdvancedTermsProps> = ({ textColor = 'black' }) => {
  const { publisherMetaData } = useLoginPage();
  const { showCookiesOverlay, hasConsent, setHasConsent, setShouldShowTermsAlert } =
    useLoginState();
  const consentRequired = useMemo(
    () => publisherMetaData?.storeTheme?.login?.consentSection?.consentRequired,
    [publisherMetaData?.storeTheme?.login?.consentSection?.consentRequired]
  );

  const isAdvancedTextFeatureEnabled = useMemo(
    () => publisherMetaData.featureFlags.dashboard_new_privacy_rich_text,
    []
  );

  const sanitizedHtmlAdvancedText = useMemo(() => {
    const dirtyHtml = publisherMetaData?.storeTheme?.login?.consentSection?.htmlText;
    if (!dirtyHtml || !isAdvancedTextFeatureEnabled) return '';

    const sanitizedHtml = DOMPurify.sanitize(dirtyHtml, {
      ALLOWED_ATTR: ['href', 'target', 'rel', 'class'],
      ALLOWED_TAGS: ['a', 'span', 'strong', 'em', 'u'],
    });

    return sanitizedHtml;
  }, [publisherMetaData?.storeTheme?.login?.consentSection?.htmlText]);

  useEffect(() => {
    setShouldShowTermsAlert(false);
    if (!consentRequired || !isAdvancedTextFeatureEnabled) return;

    setHasConsent(localStorage.getItem(ELocalStorageKeys.USER_LOGIN_TERMS_CONSENT) === 'true');
  }, [consentRequired]);

  const handleConsentChange = () => {
    const userConsentNewVal = !hasConsent;

    if (userConsentNewVal) {
      setShouldShowTermsAlert(false);

      localStorage.setItem(ELocalStorageKeys.USER_LOGIN_TERMS_CONSENT, JSON.stringify(true));
    } else {
      localStorage.removeItem(ELocalStorageKeys.USER_LOGIN_TERMS_CONSENT);
    }

    setHasConsent(userConsentNewVal);
  };

  const showConsentOverlay = useMemo(
    () => consentRequired && !hasConsent && !showCookiesOverlay,
    [consentRequired, hasConsent, showCookiesOverlay]
  );

  useEffect(() => {
    if (!showConsentOverlay) return;

    const element = document.querySelector(`.${CONSENT_SECTION_CLASS_NAME}`);
    if (element) {
      element.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }, [showConsentOverlay]);

  return (
    <StyledConsentSection
      className={CONSENT_SECTION_CLASS_NAME}
      gap={'4px'}
      shouldHighlight={showConsentOverlay}
      textColor={textColor}
    >
      {consentRequired && (
        <Checkbox
          size="small"
          className={'consent-checkbox'}
          checked={hasConsent}
          value={hasConsent}
          onChange={handleConsentChange}
          sx={{ color: textColor }}
        />
      )}
      <StyledConsentHtml
        consentRequired={consentRequired}
        dangerouslySetInnerHTML={{ __html: sanitizedHtmlAdvancedText }}
      />
    </StyledConsentSection>
  );
};
