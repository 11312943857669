import { EBundlesInternalViewModel, EConfigs } from 'constants/enums';

export const SUPPORT_IFRAME = 'hs-web-sdk-iframe';
export const SUPPORT_IFRAME_LAUNCHER = 'hs-web-sdk-iframe-launcher';

const mapBundlesConfigs: Record<EConfigs, Partial<Record<EBundlesInternalViewModel, any>>> = {
  [EConfigs.desktopMaxWidth]: {
    [EBundlesInternalViewModel.JACKPOT_SLOTS]: '400px',
    [EBundlesInternalViewModel.BIG_FISH_CASINO]: '400px',
    [EBundlesInternalViewModel.LIGHTNING_LINK_CASINO]: '400px',
    [EBundlesInternalViewModel.MIGHTY_FU_CASINO]: '400px',
    [EBundlesInternalViewModel.HEART_OF_VEGAS]: '400px',
    [EBundlesInternalViewModel.PIPA]: '528px',
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '400px',
  },
  [EConfigs.maxWidthOverride]: {
    [EBundlesInternalViewModel.MAIN_SUB]: '480px',
    [EBundlesInternalViewModel.WHISPERS]: '196px',
    [EBundlesInternalViewModel.BABA]: '400px',
  },
  [EConfigs.gapX]: {
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '18px',
    [EBundlesInternalViewModel.WHISPERS]: '17px',
    [EBundlesInternalViewModel.PIPA]: '8px',
  },
  [EConfigs.gapY]: {
    [EBundlesInternalViewModel.BAGELCODE]: '8px',
    [EBundlesInternalViewModel.BAGELCODE2]: '8px',
    [EBundlesInternalViewModel.PIPA]: '8px',
  },
  [EConfigs.marginTop]: {
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '0px',
    [EBundlesInternalViewModel.WHISPERS]: '25px',
  },
};

const defaultConfigs: Record<EConfigs, any> = {
  [EConfigs.desktopMaxWidth]: '1250px',
  [EConfigs.maxWidthOverride]: '400px',
  [EConfigs.gapX]: '12px',
  [EConfigs.gapY]: undefined,
  [EConfigs.marginTop]: '10px',
};

export const getTemplateProperty: any = (
  property: EConfigs,
  viewModel: EBundlesInternalViewModel
) => mapBundlesConfigs[property][viewModel] || defaultConfigs[property];

export const shopPageUtils = { getTemplateProperty };
