import useApi from '../../hooks/useApi';
import { Box, Button, Modal, Typography } from '@mui/material';
import { BootResponse } from '../../constants/apiResponses.types';
import { useTranslation } from 'react-i18next';

const HashValidationErrorModal = () => {
  const { t } = useTranslation();
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Modal open={true}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          color: '#FFF',
          fontFamily: publisherMetaData.storeTheme.general.font,
          width: '100%',
          padding: '0px 16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box marginBottom="15px" textAlign="center">
          <Typography
            variant="h3"
            component="h2"
            marginBottom={1.5}
            fontWeight={700}
            fontFamily="inherit"
            sx={{
              color: 'inherit',
            }}
          >
            {t('offerExpiredModal.offerExpiredTitle')}
          </Typography>
          <Typography
            component="p"
            fontWeight={400}
            fontSize="14px"
            fontFamily="inherit"
            sx={{
              color: 'inherit',
            }}
          >
            {t('offerExpiredModal.offerExpiredText')}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            width: '240px',
            height: '48px',
            padding: '14px 74px',
            borderRadius: '7px',
            borderColor: '#FFF',
            color: 'inherit',
            textTransform: 'none',
            fontFamily: 'inherit',
            '&:hover': {
              borderColor: 'inherit',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'inherit',
            },
          }}
          onClick={refreshPage}
        >
          {t('offerExpiredModal.offerExpiredButton')}
        </Button>
      </Box>
    </Modal>
  );
};

export default HashValidationErrorModal;
