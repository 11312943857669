import { useAppState } from 'state/hooks/app.state.hook';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const InfoModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { infoModalUrl, closeInfoModal, setDisplayLoader } = useAppState();
  const { t } = useTranslation();

  useEffect(() => {
    if (!infoModalUrl) return;
    setDisplayLoader(true);
  }, []);

  if (!infoModalUrl) return <></>;

  return (
    <div className="transparentOverlay" style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
      <div className="infoModal">
        <>
          <span onClick={closeInfoModal}>X</span>
          <iframe
            title={t('infoModal.legalInfo')}
            src={infoModalUrl}
            onLoad={() => {
              setDisplayLoader(false);
              setIsLoading(false);
            }}
            style={{ width: '100%', height: '100%' }}
          />
        </>
      </div>
    </div>
  );
};
